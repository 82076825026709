@font-face {
  font-family: "KondeBlack";
  font-weight: 600;
  src: local("KondeBlack"),
    url("/public/fonts/DFVN_Konde_Black.otf") format("opentype");
}

@font-face {
  font-family: "KondeRegular";
  font-weight: 500;
  src: local("KondeRegular"),
    url("/public/fonts/DFVN_Konde_Regular.otf") format("opentype");
}

@font-face {
  font-family: "KondeThin";
  font-weight: 400;
  src: local("KondeThin"),
    url("/public/fonts/DFVN_Konde_Thin.otf") format("opentype");
}

body {
  margin: 0;
  font-family: "KondeRegular", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

html,
body {
  min-height: 100%;
}
