.app-body {
  width: 100%;
  max-width: 480px;
  text-align: center;
  height: 100vh;
  height: var(--window-height);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin: 0 auto;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.point {
  text-align: center;
  text-anchor: middle;
}

.bg-animation {
  position: absolute;
  top: -60px;
  width: 100%;
}

.init-animation {
  position: absolute;
  z-index: 10;
  width: 100%;
}

.main-animation {
  position: absolute;
  z-index: 10;
  bottom: 0;
  width: 100%;
}

.background {
  top: 0;
  width: 130%;
  position: absolute;
  object-fit: cover;
  height: 100%;
}

.light-bg {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 100%;
}

.footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}

.button-action {
  width: 90%;
  height: 120px;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
}

.button-share {
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: 10;
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
}

.img-action {
  margin: auto;
  cursor: pointer;
}

.img-qrcode {
  width: 100%;
  margin: 0 auto;
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

.modal {
  display: none;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 40vw;
  border-radius: 8px;
}

.action-content {
  text-align: center;
}

.download-button {
  cursor: pointer;
  margin-left: 24px;
  height: 100px;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (min-width: 480px) {
  .img-qrcode {
    width: 480px;
  }
}

@media only screen and (max-width: 414px) {
  .background {
    width: 120%;
  }
}
